// import routes from '../router/routes'
import { Dialog, showToast } from 'vant';
export default {
  name: 'index',
  data() {
    return {
      report: {},
      signatures: 0,
      showAll: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      studentInfo: {
        idCard: '000000000000000000'
      },
      myplans: [{
        id: 1,
        name: '111111'
      }, {
        id: 2,
        name: '222222'
      }, {
        id: 3,
        name: '3333333'
      }],
      sex: ['未知', '男', '女'],
      scaleInfos: {}
    };
  },
  methods: {
    getReport() {
      var that = this;
      that.$http.get('/api/ycf/getTestReport/' + this.$route.query.id, null, '正在检索量表，请稍后').then(result => {
        console.log(result);
        if (result.success) {
          console.log(result.data);
          // showToast(JSON.stringify(result.data));
          that.report = result.data;
        } else {
          showToast(result.msg);
          //this.showQYWXCode();
        }
      });
    }
  },

  created() {},
  mounted() {
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    this.studentInfo = user.studentInfo;
    this.getReport();
  }
};